import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loadPosts } from '../../actions/posts-actions';
import 'react-circular-progressbar/dist/styles.css';
import Listing from '../modules/Listing';

import countBy from 'lodash/countBy';
import filter from 'lodash/filter';
import find from 'lodash/find';

import { generateModuleCertificatePDF } from '../../helpers/pdf';

class AcademyPageTemplate extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.loadPosts('modules');
  }

  generatePDF() {
    const { user, compulsoryModules } = this.props;

    // Generate pdf
    generateModuleCertificatePDF(compulsoryModules, user.name);
  }

  render() {
    const { stats, optionalModules, compulsoryModules } = this.props;

    // Do nothing if there is no thing to display
    if (optionalModules.length === 0 && compulsoryModules.length === 0) {
      return <p>No modules available</p>;
    }

    return (
      <div className="container">
        <article className="single page academy-margin">
          <div className="module-status">
            You have completed {stats.completedCompulsoryModules} out of {compulsoryModules.length} modules.{' '}
            <b>Continue to earn your certificate.</b>
          </div>

          <div className="row">
            <Listing modules={compulsoryModules} />

            <div key="total" className="col-6 col-md-3">
              <div className="progress-area total">
                <div className="progress-bar-container">
                  <div
                    onClick={() => {
                      this.generatePDF();
                    }}
                  >
                    <div className="progress-bar-content">
                      <div style={{ color: '#222731' }} height={120}>
                        Download Certificate
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {optionalModules.length > 0 && (
            <React.Fragment>
              <div className="row">
                <h2>More modules to further your learning</h2>
              </div>

              <div className="row">
                <Listing modules={optionalModules} />
              </div>
            </React.Fragment>
          )}
        </article>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { posts, roles, countries },
    user,
  } = state;

  // Get user type
  const type = user && user.meta && user.meta.type ? user.meta.type : '';

  // Find the current role
  let currentRole = find(roles, role => {
    return role.slug === type;
  });

  // Filter out the post that's modules for current roles
  let modules = filter(posts, post => {
    if (post.type === 'modules' && currentRole) {
      if (post.roles) {
        return post.roles.indexOf(currentRole.id) > -1;
      }
    } else {
      if (post.roles) {
        return post.roles.length === 0;
      }
    }
  });

  // Filter out by user country
  modules = modules.filter(module => {
    if (Array.isArray(module.countries) && module.countries.length > 0) {
      let found = false;
      for (const country of module.countries) {
        if (countries[country].slug === user.meta.country) {
          found = true;
        }
      }
      return found;
    } else {
      // No countries defined so include
      return true;
    }
  });

  // Sort modules into orders
  modules.sort(function (a, b) {
    return a.menuOrder > b.menuOrder ? 1 : -1;
  });

  // Seperate out the optional module and mandatory module
  const optionalModules = [];
  const compulsoryModules = [];
  const stats = {
    completedCompulsoryModules: 0,
    completedOptionalModules: 0,
  };

  // Go throught the modules and separate them out
  modules.forEach(module => {
    const questionArray = user.question[module.id];
    const numberOfQuestions = Object.keys(questionArray).filter(item => isNaN(item) && questionArray[item].correct).length;

    const counts = countBy(questionArray, 'correct');
    var completedQuestionCount = counts.correct;
    if (!completedQuestionCount) {
      completedQuestionCount = 0;
    }

    // Calculate the percentage of the module
    const progressPercentage = numberOfQuestions > 0 ? (completedQuestionCount / numberOfQuestions) * 100 : 0;
    let completedModule = 0;
    // Add to the stat
    if (progressPercentage === 100) {
      completedModule = 1;
    }

    // Properties for the module
    const item = {
      title: module.title.rendered,
      link: `/modules/${module.slug}`,
      color: module.acf.moduleColor,
      icon: module.acf.moduleIcon ? module.acf.moduleIcon : '',
      completed: progressPercentage === 100,
      incompleteCertificateIcon: module.acf.incompleteCertificateIcon,
      certificateIcon: module.acf.certificateIcon,
      progressPercentage,
    };

    // Check if its compulsory or optional module
    if (module.acf.compulsoryModule === '') {
      optionalModules.push(item);

      // Add to the stat
      stats.completedOptionalModules += completedModule;
    } else {
      compulsoryModules.push(item);

      // Add to the stat
      stats.completedCompulsoryModules += completedModule;
    }
  });

  // Add on the agp to optinal modules
  if (type === 'hcp') {
    optionalModules.push({
      title: 'AGP in Practice',
      link: '/hcp/agp',
      color: '#e6572d',
      icon: '/images/AGP_wave_icon.png',
    });
  }

  return {
    optionalModules,
    compulsoryModules,
    modules,
    stats,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadPosts,
  })(AcademyPageTemplate),
);
