/* eslint-disable react/style-prop-object */
import isExternal from 'is-url-external';
import is from 'is_js';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { loadMenus } from '../actions';
import { logout } from '../actions/authentication-actions';
import * as serviceWorker from '../serviceWorker';
import { isMobile, showOrderingPage, stripUrlKnownDomain } from '../utils';
import ReactModal from 'react-modal';

const isIrelandDomain = window.location.hostname.endsWith('.ie');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    marginBottom: '30px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '30px',
    padding: '0',
    maxHeight: 'calc(100% - 60px)',
    overflowY: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
};

class Header extends Component {
  // https://snyk.io/advisor/npm-package/react-notification-system
  notificationSystem = React.createRef();
  shownNotification = false;
  visitorInIreland = false;

  constructor(props) {
    super(props);

    this.state = {
      orientation: '',
      waitingWorker: {},
      consumerModalIsOpen: false,
      consumerSecondModalOpen: false,
      hcpModalIsOpen: false,
      hcpSecondModalOpen: false,
      modalIsClosed: false,
    };

    ReactModal.setAppElement('#root');

    this.handleOrientationChange = this.handleOrientationChange.bind(this);
  }

  static propTypes = {
    authentication: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    loadMenus: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

  afterOpenModal = () => {
    console.log('afterOpenModal');
  };

  closeConsumerSecondModal = () => {
    console.log('closeConsumerSecondModal: redirections');
    this.setState({
      consumerModalIsOpen: false,
      consumerSecondModalOpen: false,
      hcpModalIsOpen: false,
      hcpSecondModalOpen: false,
      modalIsClosed: true,
    });
    if (window.location.href.search('/consumer') >= 0) {
      // No redirecitons yet for Consunmers
    }
  };

  closeHCPSecondModal = () => {
    console.log('closeHCPSecondModal: redirections');
    this.setState({
      consumerModalIsOpen: false,
      consumerSecondModalOpen: false,
      hcpModalIsOpen: false,
      hcpSecondModalOpen: false,
      modalIsClosed: true,
    });
    if ((window.location.href.search('/hcp') >= 0 || window.location.href.search('/sign-in') >= 0) && !isIrelandDomain) {
      // Force redirections for HCPs UK
      if (window.location.href.endsWith('hcp/resources/freestyle-webinars/')) {
        window.location.replace(
          'https://pro.freestyle.abbott/uk-en/scientific-resources-education/education-training-casestudies/webinars.html',
        );
      } else if (window.location.href.endsWith('hcp/resources/tutorial-videos-hcp/')) {
        window.location.replace(
          'https://pro.freestyle.abbott/uk-en/scientific-resources-education/education-training-casestudies/tutorial-videos.html',
        );
      } else if (window.location.href.endsWith('hcp/resources/remote-consultations-with-libreview/')) {
        window.location.replace('https://pro.freestyle.abbott/uk-en/freestyle-portfolio/digital-health-solutions/libreview.html');
      } else if (window.location.href.endsWith('hcp/contact-us/')) {
        window.location.replace('https://pro.freestyle.abbott/uk-en/help-support/contact-us.html');
      } else {
        window.location.replace('https://pro.freestyle.abbott/uk-en/scientific-resources-education/libre-academy.html');
      }
    }
  };

  closeModal = () => {
    console.log('closeModal');
    this.setState({
      consumerModalIsOpen: false,
      consumerSecondModalOpen: false,
      hcpModalIsOpen: false,
      hcpSecondModalOpen: false,
      modalIsClosed: true,
    });
  };

  afterCloseModal = () => {
    console.log('afterCloseModal');
  };

  getDiscontinuePopupLink = () => {
    return this.visitorInIreland || isIrelandDomain
      ? 'https://www.freestyle.abbott/ie-en/myfreestyle/myfreestyle.html'
      : 'https://www.freestyle.abbott/uk-en/myfreestyle/myfreestyle.html';
  };

  discontinuePopupLink = () => {
    window.location.href = this.getDiscontinuePopupLink();
  };

  getHCPDiscontinuePopupLink = () => {
    return this.visitorInIreland || isIrelandDomain
      ? 'https://pro.freestyle.abbott/ie-en/scientific-resources-education/libre-academy.html'
      : 'https://pro.freestyle.abbott/uk-en/scientific-resources-education/libre-academy.html';
  };

  hcpDiscontinuePopupLink = () => {
    window.location.href = this.getHCPDiscontinuePopupLink();
  };

  onServiceWorkerUpdate = registration => {
    this.setState({
      waitingWorker: registration && registration.waiting,
    });
    const notification = this.notificationSystem.current;
    if (!this.shownNotification) {
      notification.addNotification({
        title: 'See the latest updates',
        message: 'We’ve made some updates to the content. Click here to update FreeStyle Progress to the latest content.',
        level: 'info',
        autoDismiss: 0,
        position: 'br', // tr, tl, tc, br, bl, bc
        dismissible: true,
        action: {
          label: 'Update Now',
          callback: () => {
            this.updateServiceWorker();
          },
        },
      });
      this.shownNotification = true;
    }
  };

  updateServiceWorker = () => {
    let { waitingWorker } = this.state;
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    // Delay refresh to allow SKIP_WAITING to be received
    setTimeout(function () {
      window.location.reload();
    }, 100);
  };

  componentWillMount() {
    this.props.loadMenus();
    window.addEventListener('orientationchange', this.handleOrientationChange, false);
  }

  checkDiscontinuePopups() {
    //console.log('URL has changed:', this.props.location.pathname);
    if (window.location.href.search('/consumer') >= 0 && !this.state.modalIsClosed) {
      this.setState({ consumerModalIsOpen: true });
      //this.setState({ consumerSecondModalOpen: true });
    }
    if (window.location.href.search('/hcp') >= 0 && !isIrelandDomain && !this.state.modalIsClosed) {
      //this.setState({ hcpModalIsOpen: true });
      this.setState({ hcpSecondModalOpen: true });
    }
  }

  componentDidMount = () => {
    serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });

    // Schedule periodically checks for update
    setInterval(function () {
      console.log('[SW]', `Check for updates every ${process.env.REACT_APP_UPDATE_INTERVAL_MILISECONDS / 1000} seconds`);
      serviceWorker.checkForUpdate();
    }, process.env.REACT_APP_UPDATE_INTERVAL_MILISECONDS);

    this.checkDiscontinuePopups();
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.checkDiscontinuePopups();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationChange, false);
  }

  // make sure that the menu works fine when an orientation is changed in iPad
  handleOrientationChange(e) {
    if (e.target.screen.orientation.angle === 90 || e.target.screen.orientation.angle === -90) {
      this.setState({
        orientation: 'landscape',
      });
    } else {
      this.setState({
        orientation: 'portrait',
      });
    }
  }

  rightLinks() {
    const userMeta = this.props.user;

    if (userMeta.hasOwnProperty('meta')) {
      if (userMeta.meta.type === 'consumer') {
        return (
          <React.Fragment>
            <li className="nav-item">
              <a className="nav-link" target="_blank" rel="noreferrer noopener" href="http://www.freestylediabetes.co.uk">
                Main Site
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" target="_blank" rel="noreferrer noopener" href="http://www.freestylelibre.com">
                Web Shop
              </a>
            </li>
          </React.Fragment>
        );
      } else if (userMeta.meta.type === 'hcp') {
        return (
          <React.Fragment>
            <li className="nav-item">
              <a className="nav-link" href="http://www.freestylediabetes.co.uk">
                Main Site
              </a>
            </li>
          </React.Fragment>
        );
      } else {
        return null;
      }
    }
  }

  render() {
    const { authentication, logout, menu, user } = this.props;
    // Do nothing if there is no menu
    if (!menu) {
      return null;
    }

    // console.log(deferredPrompt);
    let showAddButton = false;
    let showModal = true;
    let button;
    let modal;
    let modalDialog = '';
    const chrome = is.chrome();
    const desktop = is.desktop();
    const android = is.androidPhone();
    const ios = is.iphone();

    if (chrome && desktop) {
      showAddButton = true;
      showModal = false;
    } else if (ios || android) {
      showAddButton = false;
      showModal = true;
    } else {
      showAddButton = false;
      showModal = false;
    }

    if (ios) {
      modalDialog = 'share';
    } else if (android) {
      modalDialog = 'menu';
    }

    if (showAddButton) {
      button = (
        <button className="btn btn-orange" onClick={addToHomeScreen}>
          Download app
        </button>
      );
      modal = null;
    } else if (showModal) {
      button = null;
      modal = (
        <div className="modal add-to-home-screen">
          <h2>Add to home screen</h2>
          <p>
            Install this web app on your device for offline access: press the <span className={modalDialog}></span> button and then add to
            home screen
          </p>
          <img onClick={closeNotification} src="/icons/icon-cross.svg" alt="Close" />
        </div>
      );
    } else {
      button = null;
      modal = null;
    }

    // Additional attributes for the mobile menu
    let mobileAttributes = {};

    if (isMobile()) {
      mobileAttributes = { 'data-toggle': 'collapse', 'data-target': '#mainMenu' };
    }

    const { items } = menu;

    var parentArray = [];
    var childArray = [];

    map(items, item => {
      // Hide country related menu items
      let include = true;
      if (user.meta && user.meta.country) {
        if (user.meta.country === 'uk') {
          this.visitorInIreland = false;
          if (item.classes.includes('hide-uk')) include = false;
        } else if (user.meta.country === 'ireland') {
          this.visitorInIreland = true;
          if (item.classes.includes('hide-ie')) include = false;
        }
      }

      if (include) {
        // Strip the host name from the url
        const url = stripUrlKnownDomain(item.url);
        //const url =  item.url.replace('https://admin.freestylediabetes.co.uk', '');
        if (parseInt(item.menuItemParent) === 0) {
          parentArray.push({ id: item.iD, url: url, title: item.title, parent: item.menuItemParent, children: [] });
        }
        if (parseInt(item.menuItemParent) > 0) {
          childArray.push({ id: item.iD, url: url, title: item.title, parent: item.menuItemParent });
        }
      }
    });

    map(childArray, item => {
      let data = '';
      map(parentArray, parentItem => {
        if (parseInt(item.parent) === parseInt(parentItem.id)) {
          data = { id: item.id, url: item.url, title: item.title, parent: item.parent };
          parentItem['children'].push(data);
        }
      });
    });

    const mainMenu = map(parentArray, (item, index) => {
      return (
        <div key={`main-menu-${index}`}>
          {(() => {
            if (item.children.length > 0) {
              return (
                <li key={index} className="nav-item dropdown">
                  <Link
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="nav-link dropdown-toggle"
                    to={item.url}
                  >
                    {item.title}
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown" {...mobileAttributes}>
                    {map(item.children, (childItem, childIndex) => {
                      return isExternal(childItem.url) ? (
                        <a target="_blank" rel="noreferrer noopener" key={childIndex} className="dropdown-item" href={childItem.url}>
                          {childItem.title}
                        </a>
                      ) : (
                        <Link key={childIndex} className="dropdown-item" to={childItem.url}>
                          {childItem.title}
                        </Link>
                      );
                    })}
                  </div>
                </li>
              );
            } else {
              return (
                showOrderingPage(user, item.title) && (
                  <li key={index} className="nav-item" {...mobileAttributes}>
                    <Link role="button" className="nav-link" to={item.url}>
                      {item.title}
                    </Link>
                  </li>
                )
              );
            }
          })()}
        </div>
      );
    });

    return (
      <header className="row">
        {/* Consumer Modal */}
        <ReactModal
          isOpen={this.state.consumerModalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.afterCloseModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="customModal">
            <picture className="popup-close-btn" onClick={this.closeModal}>
              <source srcSet={process.env.PUBLIC_URL + '/images/popup_close.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup_close.png'} alt="popup_img" />
            </picture>
            <picture className="popup-left-panel d-none d-md-block">
              <source srcSet={process.env.PUBLIC_URL + '/images/popup_img.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup_img.png'} alt="popup_img" />
            </picture>
            <picture className="popup-top d-sm-block d-md-none">
              <source srcSet={process.env.PUBLIC_URL + '/images/popup-top.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup-top.png'} alt="popup_img" />
            </picture>
            <div className="popup-right-panel">
              <h2>We're migrating to a new platform SOON</h2>
              <p>Download your completion certificate before then if you need it for reimbursement.</p>
              <p className="smaller-text">
                To access all our latest exclusive content on the new platform MyFreeStyle, you'll need to activate your account by:
                <br />
                <br />
                1. Going to the{' '}
                <a target="_blank" rel="noreferrer noopener" href={this.getDiscontinuePopupLink()}>
                  FreeStyle website
                </a>{' '}
                &gt; Login &gt; Forgot your password
                <br />
                2. Entering the email address associated with your FreeStyle Progress account
                <br />
                3. Creating a new password to log in and access MyFreeStyle content
              </p>
              <div className="popup-footer">ADC-89124 V1.0 02/24</div>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.consumerSecondModalOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.afterCloseModal}
          style={customStyles}
          contentLabel="Second Modal"
        >
          <div className="customModal">
            <picture className="popup-close-btn" onClick={this.closeConsumerSecondModal}>
              <source srcSet={process.env.PUBLIC_URL + '/images/popup_close.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup_close.png'} alt="popup_img" />
            </picture>
            <picture className="popup-left-panel d-none d-md-block">
              <source srcSet={process.env.PUBLIC_URL + '/images/popup_img.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup_img.png'} alt="popup_img" />
            </picture>
            <picture className="popup-top d-sm-block d-md-none">
              <source srcSet={process.env.PUBLIC_URL + '/images/popup-top.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup-top.png'} alt="popup_img" />
            </picture>
            <div className="popup-right-panel">
              <h2>FreeStyle Progress has moved to our new knowledge hub: MyFreeStyle</h2>
              <p>
                Head to MyFreeStyle today to create your free account and get access to a wealth of resources, complete learning modules and
                unlock exclusive reward content.
              </p>
              <button onClick={this.discontinuePopupLink} className="orange-btn">
                Create your MyFreeStyle account
              </button>
            </div>
          </div>
        </ReactModal>

        {/* HCP Modal */}
        <ReactModal
          isOpen={this.state.hcpModalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.afterCloseModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="customModal">
            <picture className="popup-close-btn" onClick={this.closeModal}>
              <source srcSet={process.env.PUBLIC_URL + '/images/popup_close.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup_close.png'} alt="popup_img" />
            </picture>
            <picture className="popup-left-panel d-none d-md-block">
              <source srcSet={process.env.PUBLIC_URL + '/images/popup_img.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup_img.png'} alt="popup_img" />
            </picture>
            <picture className="popup-top d-sm-block d-md-none">
              <source srcSet={process.env.PUBLIC_URL + '/images/popup-top.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup-top.png'} alt="popup_img" />
            </picture>
            <div className="popup-right-panel">
              <h2>
                FreeStyle Progress is moving to our new platform Libre Academy on September 19<sup>th</sup>
              </h2>
              <p>Make sure you download your FreeStyle Progress completion certificate before this date if you need it for your records.</p>
              <p className="smaller-text">
                When the new platform launches, you will receive instructions for activating your new Libre Academy account to get access to
                all our learning modules and exclusive patient case studies.
              </p>
              <div className="popup-footer">ADC-97761 V1.1 08/24</div>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.hcpSecondModalOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.afterCloseModal}
          style={customStyles}
          contentLabel="Second Modal"
        >
          <div className="customModal">
            <picture className="popup-close-btn" onClick={this.closeHCPSecondModal}>
              <source srcSet={process.env.PUBLIC_URL + '/images/popup_close.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup_close.png'} alt="popup_img" />
            </picture>
            <picture className="popup-left-panel d-none d-md-block">
              <source srcSet={process.env.PUBLIC_URL + '/images/popup_img.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup_img.png'} alt="popup_img" />
            </picture>
            <picture className="popup-top d-sm-block d-md-none">
              <source srcSet={process.env.PUBLIC_URL + '/images/popup-top.png'} />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/popup-top.png'} alt="popup_img" />
            </picture>
            <div className="popup-right-panel">
              <h2>FreeStyle Progress has moved to our new knowledge hub: Libre Academy</h2>
              <p>
                Head to Libre Academy today to create your free account and get access to a wealth of resources, complete bite-sized
                e-learning modules and exclusive patient case studies.
              </p>
              <button onClick={this.hcpDiscontinuePopupLink} className="orange-btn">
                Create your Libre Academy account
              </button>
            </div>
          </div>
        </ReactModal>

        <NotificationSystem ref={this.notificationSystem} style={false} />
        <div className="header-top">
          <div className="container d-flex justify-content-between align-items-center">
            <Link to="/" title="Home">
              <picture>
                <source
                  srcSet={
                    process.env.PUBLIC_URL +
                    '/images/FS_gold-butterfly.png 1x,' +
                    process.env.PUBLIC_URL +
                    '/images/FS_gold-butterfly@2x.png 2x'
                  }
                />
                <img
                  className="img-fluid"
                  src={process.env.PUBLIC_URL + '/images/FS_gold-butterfly.png'}
                  alt="FreeStyle libre 2 Logo"
                  width="200px"
                />
              </picture>
            </Link>
            <picture>
              <source
                srcSet={process.env.PUBLIC_URL + '/images/abbott_logo.png 1x,' + process.env.PUBLIC_URL + '/images/abbott_logo@2x.png 2x'}
              />
              <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/abbott_logo.png'} alt="Abbott Logo" />
            </picture>
          </div>
        </div>
        <nav className="header-bottom navbar navbar-expand-lg navbar-light bg-dark">
          <div className="container">
            <span className="navbar-brand"></span>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#mainMenu"
              aria-controls="mainMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="mainMenu">
              <ul className="navbar-nav mr-auto">{mainMenu}</ul>
              {authentication.isAuthenticated ? (
                <ul className="navbar-nav ml-auto">
                  {this.rightLinks()}
                  <li className="nav-item" {...mobileAttributes}>
                    <Link className="nav-link" to="/my-account">
                      {user.name}
                    </Link>
                  </li>
                  <li className="nav-item" {...mobileAttributes}>
                    <span className="nav-link" onClick={logout}>
                      Logout
                    </span>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav ml-auto" {...mobileAttributes}>
                  <li className="nav-item">
                    <Link className="nav-link" to="/sign-in">
                      Login
                    </Link>
                  </li>
                  <li className="nav-item" {...mobileAttributes}>
                    <Link className="nav-link" to="/sign-up">
                      Register
                    </Link>
                  </li>
                </ul>
              )}
              {button}
            </div>
          </div>
        </nav>
        {modal}
      </header>
    );
  }
}

var deferredPrompt;

window.addEventListener('beforeinstallprompt', function (e) {
  console.log(e);
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
});

function addToHomeScreen() {
  console.log(is.chrome(), is.desktop());
  deferredPrompt.prompt(); // Wait for the user to respond to the prompt
  deferredPrompt.userChoice.then(function (choiceResult) {
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the A2HS prompt');
    } else {
      console.log('User dismissed the A2HS prompt');
    }

    deferredPrompt = null;
  });
}

function closeNotification() {
  let modal = document.getElementsByClassName('add-to-home-screen')[0];
  modal.style.display = 'none';
}

const mapStateToProps = (state, ownProps) => {
  const {
    authentication,
    entities: { menus },
    user,
  } = state;

  const menu = user.meta && user.meta.type ? menus[user.meta.type + '_menu'] : {};

  return {
    authentication,
    menu,
    user,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadMenus,
    logout,
  })(Header),
);
